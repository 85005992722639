import React from "react"
import Banner from "../../components/Hyderabad/Banner"
import Layout from "../../components/layout"
import Description from "../../components/Hyderabad/Description"
import ButtonLinks from "../../components/Hyderabad/ButtonLinks"
import Faqs from "../../components/Hyderabad/Faqs"
import OurTrustedPartners from "../../components/about/OurTrustedPartners"
import bgImage from "../../../static/assets/hyderabad/mobile-app-development.svg"
import data from "../../content/hyderabad/Description/MobileAppDevelopment.json"
import faqs from "../../content/hyderabad/Faqs/mobileappdevelopment.json"
import SEO from "../../components/seo"

const ECommerceDevelopment = () => {
  const keywords = [
    "Mobile app development services in hyderabad",
    "best Mobile app development services  company",
    "Mobile app development services",
    "Mobile app development in Hyderabad ",
    "Mobile app development company in Hyderabad",
  ]
  return (
    <Layout className="page">
      <SEO
        title="Best mobile app development services in hyderabad | Cresolinfoserv"
        description=" A leading mobile app development  services company known for its high-quality, custom mobile solutions for iOS and Android platforms. They offer end-to-end services from conceptualization to deployment and maintenance."
        keywords={keywords}
      />
      <Banner
        heading="Best Mobile app development services In Hyderabad"
        bgImage={bgImage}
      />
      <Description data={data} />
      <ButtonLinks />
      <Faqs faqs={faqs} />
      <OurTrustedPartners />
    </Layout>
  )
}

export default ECommerceDevelopment
